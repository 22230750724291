import CardComponent from '@/components/CardComponent'
import TitleBar from '@/components/TitleBar'
import HeroBar from '@/components/HeroBar'
import UserEdit from '@/components/forms/UserEdit'
import { IClient } from '@/components/forms/User.definition'
import { Vue, Component } from 'vue-property-decorator'
import ResizableDirective from '@/components/ResizableDirective'
import FTable from '@/components/FTable'
import api from '@/api'
import { uiStore } from '@/store';

@Component({
  components: {
    HeroBar,
    TitleBar,
    CardComponent,
    FTable,
  },
  directives: {
    resizable: new ResizableDirective()
  }
})
export default class Clients extends Vue {
  columns = [
    {
      label: '',
      field: 'logo',
      type: 'logo',
      width: 64,
      class: 'has-no-head-mobile is-image-cell',
    },
    {
      label: 'Назив',
      field: 'ime',
      width: 300,
      sortable: true,
      searchable: true,
    },
    {
      label: 'Град',
      field: 'grad',
      sortable: true,
    },
    {
      label: 'Адреса',
      field: 'adresa',
      sortable: true,
    },
    {
      label: 'Телефон',
      field: 'tel',
      sortable: true,
    },
    {
      label: 'Мобилни',
      field: 'mob',
      sortable: true,
    },
    {
      label: 'Факс',
      field: 'fax',
      sortable: true,
    },
    {
      label: 'ПИБ',
      field: 'pib',
      width: 112,
      sortable: true,
    },
    {
      label: 'МБР',
      field: 'matbr',
      width: 150,
      sortable: true,
    },
    {
      label: 'E-mail',
      field: 'email',
      sortable: true,
    },
    {
      label: 'Шифра',
      field: 'sifra',
      sortable: true,
    },
    {
      label: '',
      field: 'actions',
      type: 'actions',
      width: 102,
      actions: [
        {
          icon: 'account-edit',
          class: 'is-primary',
          tooltip: 'Измените клијента',
          callback: this.editPanel
        },
        {
          icon: 'trash-can',
          class: 'is-danger',
          tooltip: 'Избришите клијента',
          callback: (row: any) => this.deleteModal(row.id)
        }
      ]
    }
  ];
  trashObject: any = {};

  type = 0;
  instanceId = 0;

  get titleStack() {
    return [
      'Админ',
      'Клијенти'
    ]
  }

  get trashObjectName() {
    if (this.trashObject) {
      return this.trashObject.name
    }

    return null
  }

  editPanel(data: IClient): Promise<IClient> {
    return new Promise((resolve, reject) => {
      uiStore.openSidePanel({
        title: 'Уређивање клијента',
        component: UserEdit,
        data,
        action: (value: IClient) => {
          return api.post(`/klijenti/${data.id}`, value).then((resp: IClient) => {
            resolve(resp);
          }).catch(reject)
        }
      });
    })
  }

  addPanel() {
    uiStore.openSidePanel({
      title: 'Нови клијент',
      component: UserEdit,
      data: {},
      action: (value: IClient) => {
        return api.post('/klijenti', value).then(() => {
          this.instanceId++
        })
      }
    });
  }

  deleteModal(id: number) {
    this.$buefy.dialog.confirm({
      message: 'Да ли сте сигурни да желите да избришете клијента?',
      onConfirm: () => api.delete(`/klijenti/${id}`).then(() => {
        this.$buefy.snackbar.open({
          message: 'Клијент је успешно избрисан',
          type: 'is-success',
          queue: false
        })
      })
        .catch(() => {
          this.$buefy.snackbar.open({
            message: 'Грешка приликом брисања клијента!',
            type: 'is-danger',
            queue: false
          })
        })
        .finally(() => this.instanceId++)
    })
  }

  render() {
    return <div>
      <titleBar titleStack={this.titleStack} actions={[
        {
          icon: 'account-plus',
          class: 'is-primary',
          label: 'Нови клијент',
          callback: () => this.addPanel()
        }
      ]} />
      <bTabs position="is-centered" destroyOnHide={true} class="block" vModel={this.type}>
        <bTabItem label="Купци">
          <fTable
            title="Купци"
            zero-message="Још увек немате ни једног купаца ..."
            icon="account-multiple"
            columns={this.columns}
            endpoint="/klijenti"
            key={this.instanceId}
            filter={{ tip: 0 }}
          >
          </fTable>
        </bTabItem>
        <bTabItem label="Добављачи">
          <fTable
            title="Добављачи"
            zero-message="Још увек немате добављача ..."
            icon="account-multiple"
            columns={this.columns}
            endpoint="/klijenti"
            key={this.instanceId}
            filter={{ tip: 1 }}
          >
          </fTable>
        </bTabItem>
        <bTabItem label="Сараднци">
          <fTable
            title="Сараднци"
            zero-message="Још увек немате сарадника ..."
            icon="account-multiple"
            columns={this.columns}
            endpoint="/klijenti"
            key={this.instanceId}
            filter={{ tip: 2 }}
          >
          </fTable>
        </bTabItem>
      </bTabs>
    </div>
  }
}
